import React, { useContext } from "react"
import { StaticImage } from "gatsby-plugin-image"

import Header from "../components/Header"
import Section from "../components/Section"
import Seo from "../components/seo"
import StyledSection from "../components/StyledSection"
import Carousel from "../components/Carousel"
import Card from "../components/Card"

import { ModalContext } from "../components/layout"

const AboutPage = () => {
  const { data } = useContext(ModalContext)

  return (
    <>
      <Seo
        title="O nas"
        description={
          "O nas Od lat zajmujemy się szkoleniem przyszłych kierowców w naszym mieście i według prowadzonych rankingów świetnie nam to wychodzi! Zdobywamy pierwsze miejsca w rankingach zdających kierowców na egzaminach państwowych w WORD Tychy. Zawsze dbamy o to żeby w naszym ośrodku szkolenia nasi kursanci posiadali wszystko co potrzebne do zdania egzaminu Państwowego."
        }
      />
      <Header
        title={
          <>
            <span className="d-block header__title--primary">OSK Adept</span>
            Więcej o naszej szkole
          </>
        }
      >
        <StaticImage
          className="header__hero"
          src="../images/about-us.jpg"
          alt="szkoła jazdy"
        />
      </Header>

      <StyledSection>
        <h2 className="styled-section__title">
          Parę słów o naszej
          <br /> szkole jazdy
        </h2>
        <p className="styled-section__text">
          Od lat zajmujemy się szkoleniem przyszłych kierowców. Wieloletnie
          doświadczenie oraz odpowiednie podejście do nauczania przełożyło się
          na wyniki, osiągane przez naszych kursantów. Dzięki temu staliśmy się
          czołową szkołą jazdy w Tychach. 100% naszych kursantów jest
          zadowolonych z poziomu naszych usług, potwierdzają to opinie na naszym{" "}
          <a
            href="https://www.superprawojazdy.pl/osk-adept,9949.htm"
            target="_blank"
            rel="noreferrer"
          >
            profilu spuerprawojazdy.pl
          </a>
          .
        </p>
        <p className="styled-section__text">
          Zdajemy sobię sprawę, jak stresujące może być podchodzenie do
          egzaminów państwowych, dlatego szczególną uwagę zwracamy na zbudowanie
          pewności u naszych adeptów. Nasze zaangażowanie i starania kursantów
          przekładają się na wyniki. Od 2014 roku zajmujemy pierwsze miejsce pod
          względem zdawalności egzaminów teoretycznych.
        </p>
      </StyledSection>
      <Section title="Nasze oferty" _id="cennik">
        {data && (
          <Carousel>
            {data?.offers.map(item => (
              <Card key={item.id} {...item} />
            ))}
          </Carousel>
        )}
      </Section>
    </>
  )
}

export default AboutPage
